import React, {useEffect, useState} from 'react';

import styles from './Button.module.css';


const Button = props => {

    const [isDisabled, setIsDisabled] = useState()
    const [type, setType] = useState('')


    useEffect(() => {
        setIsDisabled(props.disabled);
    }, [props.disabled])

    useEffect(() => {
        setType(props.styleType);
    }, [props.styleType])

    return (
        <React.Fragment>

        {!isDisabled ? 
            <button 
            id={props.id} 
            type={props.type}
            onClick={props.onClick} 
            href={props.href}

            className={`${styles.button} ${styles[type]}` }
            ref={props.ref} 
        >
            <span>{props.title}</span>
        </button>

         : 
    
        <button 
            id={props.id} 
            type={props.type}
            disabled={props.disabled}
            className={`${styles.button} ${props.disabled && styles.isDisabled}` }
            ref={props.ref} 
        >
            <span>{props.title}</span>
        </button>

        }
        </React.Fragment>       
    );

}

export default Button;