import { useState, useCallback, useEffect } from 'react';
import Pusher from 'pusher-js';

export const usePusher = () => {
  const [pusher, setPusher] = useState();

  const connect = useCallback(() => {
    console.log('connecting pusher');

    !pusher && setPusher(state =>(state =
          new Pusher('410d1fb664d7cd1fc8fa', {
            cluster: 'eu',
            forceTLS: true,
          }))
    );
  }, []);

  const disconnect = useCallback(() => {
    pusher.disconnect();
    console.log('disconnected pusher');
    
  }, []);

  useEffect(()=>{
    !pusher && connect();
  })

  return { pusher, connect, disconnect };
};
