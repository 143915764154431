import React from 'react';
import { DateTime } from 'luxon';

import styles from './EventListItem.module.css'

const EventListItem = ({id, event, subTitle, selected}) => {

    const { REACT_APP_IMAGE_BASE } = process.env
    console.log(event);

    return (
        <div className={styles.row} onClick={()=>selected(id)}>
          <div className={styles.cell}>
          {event.eventBranding.masterLogo && <img className={styles.logo} alt={event.title} src={`${REACT_APP_IMAGE_BASE}/${event.eventBranding.masterLogo}`} />}
        </div>
        <div className={styles.cell}>
            <h1 className={`${styles.text} ${styles.title}`} >{event.title}</h1>
        </div>
        <div className={styles.cell}>
            <h2 className={`${styles.text} ${styles.subTitle}`} >{event.subTitle}</h2>
        </div>
        <div className={styles.cell}>
            <p className={`${styles.text}`} >{event.timeZone}</p>
        </div>
        <div className={styles.cell}>
            <p className={`${styles.text}`} >{event.type}</p>
        </div>
        <div className={styles.cell}>
            <p className={`${styles.text}`} >{DateTime.fromISO(event.date).setZone(event.timeZone).toLocaleString(DateTime.DATETIME_MED)}</p>
        </div>
            
         
        </div>
    )
}

export default EventListItem;