import { faBullhorn, faColumns, faUsers, faTachometerAlt, faCog, faArrowAltCircleLeft, faGlobe, faVideo, faPoll, faListAlt, faChalkboardUser} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {useContext} from 'react';

import { NavLink } from 'react-router-dom'; 
import { useLocation } from 'react-router-dom';
import TooltipBasic from '../UIElements/tooltip/TooltipBasic';
import { EventContext } from '../../context/event-context';


import './mainNav.css';

 const MainNav = () => {

    const location = useLocation()
    const url = location.pathname;
    const {type} = useContext(EventContext);

    return (
        <div className="main-nav__wrapper">
           { url === '/' ? 
           <ul className="nav-links">
                <li>
                    <NavLink to="/">Events</NavLink>
                </li>
            </ul>
            :
            <ul className="nav-links">
                <li>
                    <NavLink to={`/dashboard`}>
                        <TooltipBasic label='Dashboard'>
                            <FontAwesomeIcon icon={faColumns}/>
                        </TooltipBasic>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={`/announcements`}>
                        <TooltipBasic label='Announcements'>
                            <FontAwesomeIcon icon={faBullhorn}/>
                        </TooltipBasic>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={`/agenda`}>
                        <TooltipBasic label='Agenda'>
                            <FontAwesomeIcon icon={faListAlt}/>
                        </TooltipBasic>
                    </NavLink>
                </li>
                {type === 'conference' && 
                <li>
                    <NavLink to={`/conference-rooms`}>
                        <TooltipBasic label='Rooms'>
                        <FontAwesomeIcon icon={faChalkboardUser} />
                        </TooltipBasic>
                    </NavLink>
                </li>
                }
                {type === 'concert' && 
                <li>
                    <NavLink to={`/production`}>
                        <TooltipBasic label='Production'>
                        <FontAwesomeIcon icon={faChalkboardUser} />
                        </TooltipBasic>
                    </NavLink>
                </li>
                }
              
              
              
                <li>
                    <NavLink to={`/attendees`}>
                        <TooltipBasic label='Attendees'>
                            <FontAwesomeIcon icon={faUsers}/>
                        </TooltipBasic>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={`/stats`}>
                        <TooltipBasic label='Stats'>
                            <FontAwesomeIcon icon={faTachometerAlt}/>
                        </TooltipBasic>
                    </NavLink>
                </li>
               
                <li>
                    <NavLink to={`/settings`}>
                        <TooltipBasic label='Settings'>
                            <FontAwesomeIcon icon={faCog}/>
                        </TooltipBasic>
                    </NavLink>
                </li>
            </ul>
            }
            { url !== '/' &&
           <ul className="nav-links">
                <li>
                    <NavLink to="/">
                        <TooltipBasic label='My Events'>
                            <FontAwesomeIcon icon={faArrowAltCircleLeft}/>
                        </TooltipBasic>
                    </NavLink>
                </li>
            </ul>}
        </div>
    )
}


export default MainNav;