import React, { useEffect, useState, useContext } from 'react';

import { EventContext } from '../../context/event-context';
import { AuthContext } from '../../context/auth-context';
import { useHttpClient } from '../../hooks/http-hook';
import { useDateTimeZone } from '../../hooks/dateTimezone-hook';

import Input from '../FormElements/Input';
import Button from '../UIElements/Buttons/Button';
import LoadingSpinnerCenter from '../UIElements/Loading/LoadingSpinnerDark';
import ErrorModal from '../UIElements/Modal/ErrorModal';

import './CreateEventForm.css';
import EventTypeCard from '../../../event/components/EventTypeCard';
import TimeZoneSelector from '../UIElements/timeZoneSelector';

const CreateEventForm = ({ success }) => {
  const { REACT_APP_API_URL } = process.env;
  const { REACT_APP_PUBLIC_URL } = process.env;
  const auth = useContext(AuthContext);
  const eventCtx = useContext(EventContext);
  const { getEventTimeStamp, getEventUTCTimeStamp } = useDateTimeZone();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [timeZone, setTimeZone] = useState('');
  const [type, setType] = useState();
  const [types, setTypes] = useState([]);
  const [step, setStep] = useState(0);

  // getting set types from env
  //const types =  `${REACT_APP_EVENT_TYPES}`.split("&");

  useEffect(() => {
    const fetchTypes = async () => {
      try {
        const response = await sendRequest(
          `${REACT_APP_API_URL}/api/event/event-types`,
          'POST',
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token,
          }
        );
        console.log(response);
        if (response.success) {
          setTypes(response.eventTypes);
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchTypes();
  }, []);

  const onSubmit = async event => {
    event.preventDefault();
    clearError();
    console.log(event.target.uri.value);
    console.log(event.target.date.value);
    console.log(event.target.time.value);
    console.log(getEventTimeStamp(`${event.target.date.value}T${event.target.time.value}`, timeZone));
    console.log(getEventUTCTimeStamp(`${event.target.date.value}T${event.target.time.value}`, timeZone));
    
    const eventDateTime = getEventUTCTimeStamp(`${event.target.date.value}T${event.target.time.value}`, timeZone) // converting date & Time to UTC/ISO for DB
    // TODO: do some validation checks here.. for event create data

    try {
      const response = await sendRequest(
        `${REACT_APP_API_URL}/api/event/create/${type}`,
        'POST',
        JSON.stringify({
          title: event.target.title.value,
          subTitle: event.target.subTitle.value,
          type: type,
          uri: event.target.uri.value,
          supportEmail: event.target.supportEmail.value,
          date: eventDateTime,
          timeZone: timeZone,
          userId: auth.userId
        }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token,
        }
      );
      console.log(response);
      success();
    } catch (err) {
      console.log(err);
     }
  };


  const selectedZoneHandler = timeZone => {
    setTimeZone(timeZone);
  }

  return (
    <div className="create-event-form-wrapper">
      <ErrorModal error={error} onClear={clearError} />
      {!isLoading && !type && (
        <div className="typeListWrapper">
          {types.map(eventType => (
            <EventTypeCard key={eventType.id} type={eventType} selected={setType}/>
          ))}
        </div>
      )}
      {!isLoading && type && (
        <form onSubmit={onSubmit}>
          <Input
            id="title"
            name="title"
            type="text"
            placeholder="required"
            groupFront="Event Title:"
          />
          <Input
            id="subTitle"
            name="subTitle"
            type="text"
            placeholder="optional"
            groupFront="Event Sub Title:"
          />
          <Input
            id="supportEmail"
            name="supportEmail"
            type="email"
            placeholder="required"
            groupFront="Support Email:"
          />
          <label className="event-uri-label">
            This Cannot be changed! must be lowercase without spaces
          </label>
          <Input
            id="uri"
            name="uri"
            type="text"
            placeholder="Event URI"
            groupFront={`${REACT_APP_PUBLIC_URL}/`}
          />
         
            <TimeZoneSelector selectedZone={selectedZoneHandler}/>

          <Input
            id="date"
            name="date"
            type="date"
            groupFront="Event Date:"
          />

          <Input
            id="time"
            name="time"
            type="time"
            groupFront="Event start time:"
          />
          <div className="create-event-actions">
            <Button title="create" className="button-secondary" />
          </div>
         
        </form>
      
      )}
      {isLoading && <LoadingSpinnerCenter />}
    </div>
  );
};

export default CreateEventForm;
