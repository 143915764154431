import React, { Suspense, Fragment, lazy, useState } from 'react';
//import { SocketContext, socket } from './shared/context/socket';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
  faEdit,
  faChevronUp,
  faChevronDown,
  faVideo,
  faVideoSlash,
  faLock,
  faExclamationCircle,
  faPlusCircle,
  faCheckCircle,
  faTimesCircle,
  faSearch,
  faTrashAlt,
  faKey,
  faCheck,
  faCalendarAlt,
  faPoundSign,
  faSignInAlt,
  faSignOutAlt,
  faEnvelope,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';

import SideBar from './shared/components/nav/sidebar';
import Header from './shared/components/header/Header';
import Dashboard from './dashboard/pages/dashboard';
import Events from './event/pages/Events';
import Auth from './auth/pages/Auth';
import WhosOnlineContext from './shared/context/whosOnline-context';

import { useAuth } from './shared/hooks/auth-hook';
import { useEvent } from './shared/hooks/event-hook';
import { useAgenda } from './shared/hooks/agenda-hook';
import { usePusher } from './shared/hooks/pusher-hook';
import { AuthContext } from './shared/context/auth-context';
import { EventContext } from './shared/context/event-context';
import { AgendaContext } from './shared/context/agenda-context';
import { PusherContext } from './shared/context/pusher-context';
import LoadingSpinner from './shared/components/UIElements/Loading/LoadingSpinnerCenter';

import './App.css';

const Settings = lazy(() => import('./Settings/pages/Settings'));
const Agenda = lazy(() => import('./agenda/pages/Agenda'));
const Stats = lazy(() => import('./stats/pages/Stats'));
const Visitors = lazy(() => import('./visitors/pages/Visitors'));
const Announce = lazy(() => import('./Announcments/pages/Announce'));
const Forgot = lazy(() => import('./auth/pages/ForgotPassword'));
const Reset = lazy(() => import('./auth/pages/ResetPassword'));
const ConfRooms = lazy(() => import('./conferenceRooms/pages/ConferenceRooms'));
const Concert = lazy(() => import('./concert/pages/Concert'));

library.add(
  faEdit,
  faChevronUp,
  faChevronDown,
  faVideo,
  faVideoSlash,
  faLock,
  faExclamationCircle,
  faPlusCircle,
  faCheckCircle,
  faTimesCircle,
  fab,
  faTrashAlt,
  faKey,
  faCheck,
  faSignOutAlt,
  faCalendarAlt,
  faSearch,
  faEnvelope,
  faPoundSign,
  faSignInAlt,
  faExclamationTriangle
);

function App() {
  const [evt, setEvt] = useState();

  const {
    userId,
    firstName,
    lastName,
    avatar,
    email,
    token,
    login,
    logout,
    updateAvatar,
  } = useAuth();

  const {
    eventId,
    title,
    subTitle,
    type,
    uri,
    date,
    days,
    timeZone,
    open,
    supportEmail,
    lobbyKuulaCode,
    networkingKuulaCode,
    exhibitionKuulaCode,
    eventConfig,
    createEvent,
    clearEvent,
  } = useEvent();

  const { agenda, createAgenda, clearAgenda } = useAgenda();
  const { pusher, connect, disconnect } = usePusher();

  const updateEvent = event => {
    console.log(event);
    setEvt(() => event);
  };

  let routes;

  if (!token) {
    routes = (
      <Routes>
        <Route path="/auth" exact element={<Auth />} />
        <Route path="/forgot" exact element={<Forgot />} />
        <Route path="/reset/:token" element={<Reset />} />
        <Route path="*" element={<Auth />} />
      </Routes>
    );
  } else {
    routes = (
      <Fragment>
        {/* <SocketContext.Provider value={socket}> */}
        <PusherContext.Provider value={{ pusher, connect, disconnect }}>
          <SideBar />
          <Header event={evt} />
          <Routes>
            <Route path="/" exact element={<Events />} />
            <Route path="/dashboard" exact element={<Dashboard />} />
            <Route path="/agenda" exact element={<Agenda />} />
            <Route path="/announcements" exact element={<Announce />} />
            <Route path="/attendees" exact element={<Visitors />} />
            <Route path="/stats" exact element={<Stats />} />
            <Route path="/settings" exact element={<Settings />} />
            <Route path="/conference-rooms" exact element={<ConfRooms />} />
            <Route path="/production" exact element={<Concert />} />
            <Route
              path="*"
              element={<Events updateEvent={() => updateEvent} />}
            />
          </Routes>
        </PusherContext.Provider>
        {/* </SocketContext.Provider> */}
      </Fragment>
    );
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        email: email,
        avatar: avatar,
        firstName: firstName,
        lastName: lastName,
        login: login,
        logout: logout,
        updateAvatar: updateAvatar,
      }}
    >
      <EventContext.Provider
        value={{
          eventId: eventId,
          title: title,
          subTitle: subTitle,
          type: type,
          uri: uri,
          date: date,
          days: days,
          timeZone: timeZone,
          open: open,
          supportEmail: supportEmail,
          lobbyKuulaCode: lobbyKuulaCode,
          networkingKuulaCode: networkingKuulaCode,
          exhibitionKuulaCode: exhibitionKuulaCode,
          eventConfig: eventConfig,
          createEvent: createEvent,
          clearEvent: clearEvent,
        }}
      >
        <AgendaContext.Provider
          value={{
            agenda: agenda,
            createAgenda: createAgenda,
            clearAgenda: clearAgenda,
          }}
        >
          <WhosOnlineContext>
            <Router>
              <main>
                <Suspense fallback={<LoadingSpinner message="Downloading.." />}>
                  {routes}
                </Suspense>
              </main>
            </Router>
          </WhosOnlineContext>
        </AgendaContext.Provider>
      </EventContext.Provider>
    </AuthContext.Provider>
  );
}

export default App;
