import React from 'react';

import Modal from './Modal';

import styles from './ErrorModal.module.css';


const ErrorModal = ({error, onClear}) => {
  return (
    <Modal
      modalClassName={styles.errorModal}
      onCancel={onClear}
      show={!!error}
      hideButton
      footer={<button className={styles.modalBtn} onClick={onClear}>OK</button>}
    >
    <h1 className={styles.title}>Error!</h1>
      <p className={styles.text}>{error}</p>
    </Modal>
  );
};

export default ErrorModal;
