import React, { Fragment, useContext } from 'react';

import { EventContext } from '../../shared/context/event-context';

import MapLarge from '../../shared/components/maps/MapLarge';
import WhosOnline from '../../shared/components/WhosOnline/WhosOnline';
import Summery from '../components/Summery';

import './dashboard.css';
import styles from './dashboard.module.css';

const Dashboard = () => {

  const { eventId } = useContext(EventContext);

  return (
    <>
      <div className={styles.pageWrapper}>
        <MapLarge/>
        <div className={styles.summeryWrapper}>
          <Summery eventId={eventId}/>
        </div>
        <div className={styles.whosonlineWrapper}>
          <WhosOnline />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
