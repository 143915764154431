import React from 'react';

import styles from './LoadingSpinner.module.css';

const LoadingSpinnerCenter = props => {
  return (
    <div className={styles.loading}>
      <div className={styles.loadingContiner}>
        <span className={`${styles.loader} ${props.light && styles.white} ${props.dark && styles.black}`}></span>
        <p id={styles.loadingMessage}>{`${
          props.message || 'Please wait...'
        }`}</p>
      </div>{' '}
    </div>
  );
};

export default LoadingSpinnerCenter;

