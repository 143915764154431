import { useEffect, useContext } from 'react';
import { AuthContext } from '../context/auth-context';
//import { SocketContext } from '../context/socket';
import { PusherContext } from '../context/pusher-context';

export const useCheckSocket = () => {

  const {isLoggedIn} = useContext(AuthContext);
  //const socket = useContext(SocketContext);
  const { pusher, connect } = useContext(PusherContext);

// check connection status
useEffect(() => {
    if (!pusher && isLoggedIn) {
      console.log('socket');
      connect();
    }
}, [pusher, isLoggedIn]);
  
};


