import { faChalkboardTeacher} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'

import styles from './EventTypeCard.module.css';

const EventTypeCard = ({type, selected}) => {
  return (
    <div className={styles.wrapper} onClick={()=>selected(type.typeName)}>
        <div className={styles.thumb}>
         <FontAwesomeIcon icon={faChalkboardTeacher }/>
        </div>
        <h1 className={styles.title}>{type.typeName}</h1>
        <p className={styles.desc}>{type.description}</p>
    
    </div>
  )
}

export default EventTypeCard