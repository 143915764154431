import React from 'react'
import { useNavigate } from 'react-router-dom';

import './logo.css'

 const Logo = ({imgClassName, white, mono}) => {
    return (
        <div className="logo__wrapper">
             {white && !mono && <img className={`logo-img ${imgClassName} `} src="/assets/images/logo-white.png" alt="logo"/>}
             {mono && !white && <img className={`logo-img ${imgClassName} `} src="/assets/images/logo.svg" alt="logo"/>}
             {!white && !mono && <img className={`logo-img ${imgClassName} `} src="/assets/images/logo.png" alt="logo"/>}
        </div>
    )
}


export default Logo;