import { faSearchLocation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import Button from '../Buttons/Button';
import Modal from '../Modal/Modal';

import styles from './timeZoneSelector.module.css';

const getGroupedTimeZones = () => {
  const z = Intl.supportedValuesOf('timeZone');
  var zones = [[z[0]]];
  z.slice(1).forEach((e, i) => {
    if (e.split('/')[0] === z[i].split('/')[0]) {
      zones[zones.length - 1].push(e);
    } else {
      zones.push([e]);
    }
  });
  return zones;
};

const TimeZoneSelector = ({init, selectedZone }) => {
  const groupedTimeZones = getGroupedTimeZones();
  const [zoneRegions, setZoneLabels] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState([]);
  const [filteredRegions, setFilteredRegions] = useState([]);
  const [filter, setFilter] = useState('');
  const [showTimeZonePicker, setShowTimeZonePicker] = useState(false);
  const [selectedZoneLabel, setSelectedZoneLabel] = useState(
    'Please select a timezone'
  );

  useEffect(() => {
    init && setSelectedZoneLabel(init)
  }, [init])
  


  useEffect(() => {
    let arry = [];
    groupedTimeZones.forEach((item, i) => {
      console.log(item[0].split('/')[0]);
      arry = [...arry, { label: item[0].split('/')[0], id: i }];
    });
    console.log(arry);
    setZoneLabels(arry);
    setFilteredRegions([...groupedTimeZones[7]]);
    setSelectedRegion([...groupedTimeZones[7]]);
    
  }, []);

  const updateFilterHandler = event => {
    setFilter(event.target.value);
    setFilteredRegions(current => [
      ...selectedRegion.filter(item => {
        console.log(item.toLowerCase());
        return item
          .split('/')[1]
          .toLowerCase()
          .includes(event.target.value.toLowerCase());
      }),
    ]);
  };

  const chooseRegionHandler = id => {
    setSelectedRegion([...groupedTimeZones[id]]);
    setFilteredRegions([...groupedTimeZones[id]]);
  };

  const chosenTimeZoneHandler = timeZone => {
    setShowTimeZonePicker(false);
    setSelectedZoneLabel(timeZone);
    selectedZone(timeZone);
  };

  return (
    <div id={styles.timeZoneSelector}>
      <div id={styles.timezone}>
        <div className={styles.regionList}>
          <Button
            type="button"
            onClick={() => setShowTimeZonePicker(true)}
            title="Choose Timezone"
          />
        </div>
        <div className={styles.labelWrapper}>
          <p className={styles.zoneLabel}>{selectedZoneLabel}</p>
        </div>
      </div>

      <Modal show={showTimeZonePicker} onCancel={()=>setShowTimeZonePicker(false)}>
        <div className={styles.wrapper}>
          <div className={styles.regionWrapper}>
            {zoneRegions.length > 0 && (
              <ul className={styles.regionList}>
                {zoneRegions.map((region, i) => (
                  <li
                    key={i}
                    className={styles.regionLabel}
                    onClick={() => chooseRegionHandler(region.id)}
                  >
                    {region.label}
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className={styles.zoneWrapper}>
            <div className={styles.zoneHeader}>
              {selectedRegion.length > 6 && (
                <h1>{selectedRegion[0].split('/')[0]}</h1>
              )}
              <div className={styles.filterWrapper}>
                <input
                  id="filter"
                  className={styles.filterInput}
                  placeholder="search zone..."
                  value={filter}
                  onChange={updateFilterHandler}
                />
                <span className={styles.icon}>
                  <FontAwesomeIcon icon={faSearchLocation} />
                </span>
              </div>
            </div>
            {filteredRegions.length > 0 && (
              <ul className={styles.subRegionList}>
                {filteredRegions.map((item, i) => (
                  <li
                    key={i}
                    className={styles.subRegionItem}
                    onClick={() => chosenTimeZoneHandler(item)}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TimeZoneSelector;
