import { useState, useCallback, useEffect } from 'react';

let logoutTimer;

export const useAuth = () => {
  const [token, setToken] = useState(null);
  const [tokenExpirationDate, setTokenExpirationDate] = useState();
  const [userId, setUserId] = useState(null);
  const [email, setEmail] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [lang, setLang] = useState(null);
  
  const updateAvatar = useCallback((avatar) => {
    setAvatar(avatar);
    localStorage.setItem(
      'userData',
      JSON.stringify({
        avatar: avatar,
      })
    );
  },[]);

  const login = useCallback(
    (id, email, firstName, lastName, avatar, lang, token, expirationDate) => {
      setToken(token);
      setUserId(id);
      setEmail(email);
      setFirstName(firstName);
      setLastName(lastName);
      setAvatar(avatar);
      setLang(lang);
      const tokenExpirationDate = expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60 * 12);
      setTokenExpirationDate(tokenExpirationDate);
      localStorage.setItem(
        'userData',
        JSON.stringify({
          userId: id,
          token: token,
          email: email,
          firstName: firstName,
          lastName: lastName,
          avatar: avatar,
          lang: lang,
          expiration: tokenExpirationDate.toISOString(),
        })
      );
    },
    []
  );

  const logout = useCallback(() => {
    setToken(null);
    setTokenExpirationDate(null);
    setUserId(null);
    setEmail(null);
    setFirstName(null);
    setLastName(null);
    setAvatar(null);
    setLang(null);
    localStorage.removeItem('userData');
  }, []);

  useEffect(() => {
    if (token && tokenExpirationDate) {
      const remainingTime = tokenExpirationDate.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime);
     
    } else {
      clearTimeout(logoutTimer);
    }
  }, [token, logout, tokenExpirationDate]);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('userData'));
    if (
      storedData &&
      storedData.token &&
      new Date(storedData.expiration) > new Date()
    ) {
      login(
        storedData.userId,
        storedData.email,
        storedData.firstName,
        storedData.lastName,
        storedData.avatar,
        storedData.lang,
        storedData.token,
        new Date(storedData.expiration)
      );
    }
  }, [login]);

  return { userId, email, firstName, lastName, avatar, lang, token, login, logout, updateAvatar };
};
