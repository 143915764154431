import React, {useState, useEffect, useContext}  from 'react';
import { useNavigate } from "react-router-dom";

import { EventContext } from '../../shared/context/event-context';
import { PusherContext } from '../../shared/context/pusher-context';
import SubMenu from '../../shared/components/nav/subMenu';
import EventList from '../components/EventList';
import CreateEvent from '../components/CreateEvent';
//import { connect } from 'socket.io-client';

const subMenu = [
  {
    id: 0,
    title: 'My Events'
  },
  {
    id: 1,
    title: 'Create Event'
  }
]

const Events = ({ setEventData}) => {
 
  let navigation = useNavigate();
  const { createEvent, clearEvent } = useContext(EventContext);
  const { pusher, connect} = useContext(PusherContext);
  const [showEventList, setShowEventList] = useState(true);
  const [showAddEvent, setShowAddEvent] = useState(false);

  useEffect(() => {
    !pusher && connect();
  }, [])
  

  useEffect(() => {
    clearEvent();
  }, [])

  const selectEventHandler = eventData => {
    console.log(eventData);
    if (eventData) {
      createEvent(
        eventData.id,
        eventData.title,
        eventData.subTitle,
        eventData.type,
        eventData.uri,
        eventData.date,
        eventData.days,
        eventData.timeZone,
        eventData.open,
        eventData.supportEmail,
        eventData.lobbyKuulaCode,
        eventData.networkingKuulaCode,
        eventData.exhibitionKuulaCode,
        eventData.eventConfig
        
      );
      navigation(`/dashboard`);
    }
  }

  const subMenuStateHandler = (id) => {
    console.log(id);
    
    switch (id) {
      case 0:
        setShowAddEvent(false);
        setShowEventList(true);        
        break;
      case 1:
        setShowAddEvent(true);
        setShowEventList(false);        
        break;
    
      default:
        break;
    }
  }
  
  return (
    <div className="page__wrapper">
    <SubMenu items={subMenu} click={subMenuStateHandler} />
    <div className="page-content clear-header">
      {showEventList && <EventList selectedEvent={selectEventHandler} createEvent={()=>subMenuStateHandler(1)}/>}
      {showAddEvent && <CreateEvent success={()=>subMenuStateHandler(0)} />}
  
    </div>
  </div>
  );
};

export default Events;
