import { useState, useCallback, useEffect } from 'react';

export const useAgenda = () => {
  const [agenda, setAgenda] = useState([null]);
  
  const createAgenda = useCallback(( agenda ) => {

    setAgenda([...agenda]);
   
    console.log('setting Agenda Context');
    
    localStorage.setItem(
        'agendaData',
        JSON.stringify({
          agenda: agenda
        })
      );
    },
    []
  );

  const clearAgenda = useCallback(() => {
    setAgenda(null);
    localStorage.removeItem('agendaData');
  }, []);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('agendaData'));
    storedData && createAgenda(
    storedData.agenda
    );
    
  }, [createAgenda]);

  return { agenda, createAgenda, clearAgenda };
};
