import React, { useEffect, useState, Fragment } from 'react';
import { faPoll } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useHttpClient } from '../../shared/hooks/http-hook';
import SqaureCard from '../../shared/components/UIElements/SqaureCard';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';

import styles from '../pages/dashboard.module.css';

const PollCount = ({eventId}) => {

    const { REACT_APP_API_URL } = process.env;
    const { isLoading, sendRequest, error, clearError } = useHttpClient();
    const [value, setValue] = useState('0');

    
    useEffect(() => {
        const fetchPollCount = async () => {
          try {
            const responseData = await sendRequest(
              `${REACT_APP_API_URL}/api/poll/poll-count/${eventId}`
            );
            console.log(responseData.pollCount);
            if (responseData.pollCount) {
               
              setValue(responseData.pollCount);
            }
          } catch (err) {
            console.log(err);
          }
        };
        fetchPollCount();
      }, []);

    return (
        <Fragment>
            <ErrorModal error={error} onClear={clearError} />
            <SqaureCard  className={styles.card} value={value} label='Poll Count' isLoading={isLoading}>       
                    <FontAwesomeIcon icon={faPoll} />
            </SqaureCard>
        </Fragment>
    )
}

export default PollCount
