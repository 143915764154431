import { useState, useCallback } from 'react';
import { DateTime } from 'luxon';

export const useDateTimeZone = () => {

  const [dateData, setDateData] = useState(null);

  const getDateTimeZone = useCallback((isoDate, eventTimeZone ) => {

    console.log(isoDate);
    console.log(eventTimeZone);

    //takes string from db and converts to both timezones
    //user local time
    const localDateTime = DateTime.fromISO(isoDate);

     //set event time zone for event local time   
     const eventDateTime = DateTime.fromISO(isoDate, {
      zone: eventTimeZone
    });
    const data = {local: localDateTime, event: eventDateTime};
    console.log(data);
    setDateData(data);
    return data;
      
    },[]
  );

  const getEventTimeStamp = useCallback((dateTimeISO, eventTimeZone ) => {
    // dateTimeISO -> expected format "yyyy-MM-ddThh:mm"
    // eventTimeZone -> expected format "Eurpoe/London"
    console.log(dateTimeISO);
    console.log(eventTimeZone);
    var dateWithTZ = DateTime.fromISO(dateTimeISO, { zone: eventTimeZone });
    return dateWithTZ.toString(); 
  },[]
  );

  const getEventUTCTimeStamp = useCallback((dateTimeISO, eventTimeZone ) => {
    // dateTimeISO -> expected format "yyyy-MM-ddThh:mm"
    // eventTimeZone -> expected format "Eurpoe/London"
    console.log(dateTimeISO);
    console.log(eventTimeZone);
    var dateWithTZ = DateTime.fromISO(dateTimeISO, { zone: eventTimeZone });
    return dateWithTZ.toUTC().toString(); 
  },[]
  );

  const getEventDays = useCallback((days, startDateUTC, eventTimeZone ) => {
    let eventdays = [];

    if (eventdays.length === 0) {
      // date is event start date in UTC/ISO
      // loop thought number of event dats to generate array dayDate objects
      for (let i = 0; i < parseInt(days); i++) {
        const eventDayDateString = DateTime.fromISO(startDateUTC, { zone: eventTimeZone })
          .plus({ days: i })
          .toLocaleString(DateTime.DATE_HUGE); // add 1 day per iteration using loop count index
        const eventDayDateISO = DateTime.fromISO(startDateUTC, { zone: eventTimeZone })
          .plus({ days: i })
          .toISO();
        const daySelectData = {
          day: i + 1,
          label: eventDayDateString,
          date: eventDayDateISO,
        };
        eventdays.push(daySelectData);
      }
      console.log(eventdays);
      return eventdays;
    }
  },[]
  );



  return { getDateTimeZone, getEventTimeStamp, getEventUTCTimeStamp, getEventDays, dateData };
};
