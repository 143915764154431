import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import store from './store/index';
import './index.css';
import App from './App';

import LoadingSpinner from './shared/components/UIElements/Loading/LoadingSpinnerDark';

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<div className='center'><LoadingSpinner /></div>}>
      <App />
    </Suspense>
  </Provider>
    ,
  document.getElementById('root')
);

