import React, {useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AuthContext } from '../../context/auth-context';
import { useCheckSocket } from '../../hooks/socket-conn-hook';
import AudienceCount from '../Analytics/AudienceCount';
import useWhosOnline from '../../hooks/online-hook';
import EventCheckbox from '../FormElements/EventCheckbox';

import styles from './Header.module.css';
import { EventContext } from '../../context/event-context';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';


const Header = props => {

  const { logout } = useContext(AuthContext);
  const eventCtx = useContext(EventContext)
  useCheckSocket();

  useEffect(() => {
    console.log('header update');
   console.log(eventCtx);
   console.log(props.event);
  }, [eventCtx])

  useWhosOnline();

  return (
    <div className={styles.pageHeaderWrapper}>
    <div className={styles.titleWrapper}>
    {eventCtx.title && <h1 className={styles.eventTitle}>{eventCtx.title}</h1>}
    </div>
      <div className={styles.eventToggleWrapper}>
      <EventCheckbox event={props.event}/>
       <AudienceCount />
        <button onClick={logout} className={styles.logout}>
      
          <FontAwesomeIcon icon={faSignOutAlt} />
         
        </button>
      </div>
    </div>
  );
};

export default Header;
